import React from 'react';
import classNames from 'classnames';

const LinkAwareText = ({ text = '', linkIsSecondaryColor = false }) => {
  /*
  The way this work is by breaking the text into separate chunks, where the breaking
    point is the URL. The chunks are then mapped to a JSX element. If the chunk is a URL,
    it will be wrapped in an anchor tag, otherwise it will be put as a normal string.

    E.g.

    text = "Hello, I am a link https://www.google.com"

    It will be broken into
    [
        "Hello, I am a link ",
        "https://www.google.com"
    ]
   */
  let mappedText = [];

  const linkRegex =
    /(?:https?:\/\/)?[A-Za-z0-9-_.]+\.[a-z]{2,12}(?:\/[^\s]*)?/gm;
  const emailRegex = /([\w-+.]+@[\w-+.]+\.[a-zA-Z]{2,4})/g;

  // text must be a string
  if (typeof text !== 'string') return '';

  // Split all whitespace and line-breaks
  const textParts = text.split(/(\s+)/);

  const links = text.match(linkRegex);
  const emails = text.match(emailRegex);

  textParts.forEach((textPart) => {
    // push all link break and spacing to mappedText instantly
    if (textPart.includes('\n') || textPart === ' ') {
      mappedText.push(textPart);
      return;
    }

    // If textPart is not a spacing or linebreak then check if its an email or link
    if (emails?.includes(textPart)) {
      const emailLink = `mailto:${textPart}`;
      mappedText.push(
        <a
          key={textPart}
          href={emailLink}
          target="_blank"
          rel="noopener noreferrer"
          className="font-medium text-npl-yellow-light-solid-11">
          {textPart}
        </a>
      );
      mappedText.push(' ');
      return;
    }

    if (links?.includes(textPart)) {
      const hasProtocol = textPart.startsWith('http');
      const link = hasProtocol ? textPart : `https://${textPart}`;
      mappedText.push(
        <a
          key={textPart}
          href={link}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className={classNames(' hover:underline', {
            'text-npl-yellow-light-solid-11': !linkIsSecondaryColor,
            'text-npl-text-icon-on-light-surface-secondary/65':
              linkIsSecondaryColor
          })}>
          {textPart}
        </a>
      );
      mappedText.push(' ');
      return;
    }

    mappedText.push(textPart);
  });
  return mappedText;
};

export default LinkAwareText;
