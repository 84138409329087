import NextImage, { NextImagePropTypes } from './NextImage';

export type ImagePropType = {
  src: string;
  width: number | string;
  height: number | string;
  objectFit: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
};

export type NextImageType = {
  alt: string;
  mobileImgProps: ImagePropType;
  tabletImgProps: ImagePropType;
  desktopImgProps: ImagePropType;
  isDynamic: boolean;
  style: React.CSSProperties;
  className: string;
};

export { NextImagePropTypes };
export default NextImage;
