export const PRICE_TYPES = {
  FIXED: 'FIXED',
  FLEXIBLE: 'FLEXIBLE'
};

export const placeholderAmount = {
  AED: {
    fixedAmount: 30,
    suggestedAmount: 30
  },
  ARS: {
    fixedAmount: 3500,
    suggestedAmount: 3500
  },
  AUD: {
    fixedAmount: 15,
    suggestedAmount: 15
  },
  BRL: {
    fixedAmount: 50,
    suggestedAmount: 50
  },
  CAD: {
    fixedAmount: 10,
    suggestedAmount: 10
  },
  CLP: {
    fixedAmount: 8000,
    suggestedAmount: 8000
  },
  COP: {
    fixedAmount: 40000,
    suggestedAmount: 40000
  },
  EUR: {
    fixedAmount: 8,
    suggestedAmount: 8
  },
  GBP: {
    fixedAmount: 8,
    suggestedAmount: 8
  },
  HUF: {
    fixedAmount: 3500,
    suggestedAmount: 3500
  },
  IDR: {
    fixedAmount: 150000,
    suggestedAmount: 150000
  },
  ILS: {
    fixedAmount: 40,
    suggestedAmount: 40
  },
  INR: {
    fixedAmount: 800,
    suggestedAmount: 800
  },
  MXN: {
    fixedAmount: 200,
    suggestedAmount: 200
  },
  MYR: {
    fixedAmount: 40,
    suggestedAmount: 40
  },
  PEN: {
    fixedAmount: 40,
    suggestedAmount: 40
  },
  PHP: {
    fixedAmount: 500,
    suggestedAmount: 500
  },
  SGD: {
    fixedAmount: 10,
    suggestedAmount: 10
  },
  USD: {
    fixedAmount: 10,
    suggestedAmount: 10
  },
  VND: {
    fixedAmount: 240000,
    suggestedAmount: 240000
  }
};
